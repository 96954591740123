import React, { useMemo, useState } from 'react'
import CreateStrategyForm from './form'
import { strategyService } from '../../services'
import { toast, ToastContainer } from 'react-toastify'
import AirTable from '../ui/table'
import { useRequest } from '../../services/api'
import moment from 'moment'
import Popover from '../../ui/popper'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import EditStrategyForm from './edit-form'

function SignalStrategiesPage() {
  const [page, setPage] = useState(1)
  const [showEditModal, setShowEditModal] = useState(false)
  const [strategyData, setStrategyData] = useState({})

  const { data, revalidate } = useRequest('/admin/options-auto-signals/strategies')
  const { strategies = [] } = data || {}

  const { data: optionsAutoSignalsData } = useRequest(`/admin/options-auto-signals?page=${page}&pageSize=50`)
  const { signals = [], totalPages, totalItems } = optionsAutoSignalsData || {}

  const onCreateStrategy = async (data) => {
    await strategyService.create(data)
    toast.success('New strategy created')
    await revalidate()
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Entry Conditions',
        accessor: 'indicators',
        Cell({ value }) {
          return value.filter(v => v.type === 'entry').map(item => item.value).join(', ')
        }
      },
      {
        Header: 'PT Conditions',
        accessor: '',
        Cell({ row }) {
          const value = row.original.indicators
          return value.filter(v => v.type === 'price-target').map(item => item.value).join(', ')
        }
      },
      {
        Header: 'SL Conditions',
        accessor: '',
        Cell({ row }) {
          const value = row.original.indicators
          return value.filter(v => v.type === 'stop-loss').map(item => item.value).join(', ')
        }
      },
      {
        Header: 'Contract Type',
        accessor: 'contractType',
      },
      {
        Header: 'Account Size',
        accessor: 'accountSize',
      },
      {
        Header: 'Price From',
        accessor: 'optionPriceFrom',
      },
      {
        Header: 'Price To',
        accessor: 'optionPriceTo',
      },
      {
        Header: 'Price Target',
        accessor: 'priceTarget',
      },
      {
        Header: 'Stop Loss',
        accessor: 'stopLoss',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell({ value }) {
          return value ? 'Yes' : 'No'
        }
      },
      {
        Header: 'Symbols',
        accessor: 'symbols',
        Cell({ value }) {
          return value ? value.join(', ') : ''
        }
      },
      {
        Header: 'Env',
        accessor: 'env',
      },
      {
        Header: 'Expiration',
        accessor: 'expirationDatePosition',
      },
      {
        Header: 'Strike',
        accessor: 'strikePosition',
      },
      {
        Header: 'Resolution',
        accessor: 'resolution',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell({ value }) {
          return moment(value).format('MM/DD/YYYY HH:mm')
        }
      },
      {
        Header: 'Details',
        accessor: (row) => row,
        Cell({ value: row }) {
          return (
            <div className="d-flex justify-center items-center">
              <button
                className="btn btn-sm btn-info mr-1"
                onClick={() => {
                  setStrategyData({
                    id: row.id,
                    active: row.active,
                    env: row.env,
                    optionPriceFrom: row.optionPriceFrom,
                    optionPriceTo: row.optionPriceTo,
                    accountSize: row.accountSize,
                  })
                  setShowEditModal(true)
                }}
              >
                Edit
              </button>
              <Popover
                trigger="click"
                tooltip={
                  <div className="p-4" style={{ maxWidth: 200 }}>
                    <p className="">Are you sure that you want to delete this strategy?</p>
                    <div className="flex justify-end">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={async () => {
                          try {
                            await strategyService.delete(row.id)
                            toast.success(`Successfully deleted strategy`)
                            await revalidate()
                          } catch (err) {
                            toast.error(err.message)
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                }
              >
                <button className="btn btn-sm btn-danger">
                  Delete
                </button>
              </Popover>
            </div>
          )
        },
      },
    ]
  }, [])
  const signalsColumns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Symbol',
        accessor: 'underlyingSymbol',
      },
      {
        Header: 'Options Contract',
        accessor: 'ticker',
      },
      {
        Header: 'P. Entry price',
        accessor: 'price',
      },
      {
        Header: 'T. Entry price',
        accessor: 'openOrderPrice',
      },
      {
        Header: 'P. Current price',
        accessor: 'lastPrice',
      },
      {
        Header: 'P. Exit Price',
        accessor: 'endPrice',
      },
      {
        Header: 'T. Exit Price',
        accessor: 'closeOrderPrice',
      },
      {
        Header: 'P. Highest price',
        accessor: 'highestPrice',
      },
      {
        Header: 'Current % change',
        accessor: 'currentChange',
      },
      {
        Header: 'End % change',
        accessor: 'endChange',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Resolution',
        accessor: 'resolution',
      },
      {
        Header: 'Contract Type',
        accessor: 'contractType',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
      {
        Header: 'Finished At',
        accessor: 'finishedAt',
      },
      {
        Header: 'Expiration date',
        accessor: 'expirationDate',
      },
      {
        Header: 'Stop Loss',
        accessor: 'stopLoss',
      },
      {
        Header: 'Price Target',
        accessor: 'priceTarget',
      },
      {
        Header: 'Strategy',
        accessor: 'strategyName',
      },
      {
        Header: 'Env',
        accessor: 'env',
      },
      {
        Header: 'Tradier Account ID',
        accessor: 'tradier_account_id',
      },
      {
        Header: 'Alpaca Account ID',
        accessor: 'alpacaAccountId',
      },
      {
        Header: 'Indicators',
        accessor: 'indicators',
        Cell({ value }) {
          return value.map(item => item.value).join(', ')
        }
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <ToastContainer/>
      <h4>Create Strategy</h4>
      <CreateStrategyForm onSave={onCreateStrategy}/>
      <div className="row mt-5">
        <div className="col-md-12">
          <h4>Strategies List</h4>
          <AirTable
            columns={columns}
            data={strategies}
            loading={!data}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <h4>Options auto signals</h4>
          <AirTable
            columns={signalsColumns}
            data={signals}
            loading={!optionsAutoSignalsData}
            totalItem={totalItems}
            totalPage={totalPages}
            onChangePageIndex={setPage}
            pagging
          />
        </div>
      </div>

      <Modal
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false)
          setStrategyData({})
        }}
        center
      >
        <div className="mt-4">
          <EditStrategyForm
            revalidate={revalidate}
            data={strategyData}
            closeModal={() => {
              setShowEditModal(false)
              setStrategyData({})
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default SignalStrategiesPage
